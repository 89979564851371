import React from 'react'
import PropTypes from 'prop-types'
import { compose, withState } from 'recompose'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import ButtonBase from '@material-ui/core/ButtonBase'
import Popover from '@material-ui/core/Popover'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import withStyles from '@material-ui/core/styles/withStyles'
import Fade from '@material-ui/core/Fade'
import t from '../helpers/translate'
import AvatarOrFirstLatter from './AvatarOrFirstLatter'

const styles = theme => ({
  root: {
    '& *': {
      color: '#fff'
    }
  },
  popover: {
    marginTop: 12,
    '& *': {
      color: theme.components.popover.color
    }
  },
  menu: {
    borderRadius: 0
  },
  item: {
    height: 15
  },
  top: {
    display: 'flex',
    padding: 16,
    borderBottom: '1px solid #ddd',
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 15
  },
})

const enhance = compose(
  withStyles(styles),
  withState('state', 'setState', { open: false, anchorEl: null })
)

const Profile = ({ classes, state, setState, ...props }) => (
  <div className={classes.root}>
    <ButtonBase
      disableRipple={true}
      className={classes.button}
      onClick={event => setState({ open: true, anchorEl: event.currentTarget })}>
      <AvatarOrFirstLatter
        src={props.profile.img}
        username={props.profile.username}
      />
    </ButtonBase>
    <Popover
      {...state}
      className={classes.popover}
      classes={{ paper: classes.menu }}
      onClose={() => setState({ open: false, anchorEl: null })}
      TransitionComponent={Fade}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <div className={classes.top}>
        <AvatarOrFirstLatter
          src={props.profile.img}
          username={props.profile.username}
        />
        <div className={classes.profile}>
          <strong>{props.profile.username}</strong>
          <span>{props.profile.email || t('Email not entered')}</span>
        </div>
      </div>
      <MenuList>
        <MenuItem className={classes.item} onClick={props.logout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText inset={true} primary={t('Logout')} />
        </MenuItem>
      </MenuList>
    </Popover>
  </div>
)

Profile.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
}

export default enhance(Profile)
