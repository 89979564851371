import { withRouter } from 'react-router'
import { compose } from 'recompose'
import UserIsAuthenticated from '../../../permissions/UserIsAuthenticated'
import HasPermission from '../../../permissions/HasPermission'
import Settings from './../components/Settings'

export default compose(
  UserIsAuthenticated,
  HasPermission(['list_user']),
  withRouter
)(Settings)
