/* eslint-disable max-len */
import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const DarkIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M21.64 12.9999C21.4957 12.8806 21.3207 12.8042 21.1351 12.7795C20.9494 12.7547 20.7606 12.7826 20.59
    12.8599C19.5326 13.3438 18.3829 13.5928 17.22 13.5899C15.0689 13.5873 13.006 12.7344 11.4812 11.217C9.95632
    9.69967 9.09321 7.64102 9.08001 5.48991C9.08457 4.81574 9.16848 4.14445 9.33001 3.48991C9.36429 3.31545
    9.35143 3.135 9.29277 2.96716C9.2341 2.79932 9.13175 2.65016 8.99626 2.53504C8.86076 2.41992 8.69702 2.343 8.52191
    2.31222C8.3468 2.28144 8.16665 2.2979 8.00001 2.35991C6.43234 3.06482 5.06958 4.15667 4.03977 5.53289C3.00996
    6.90911 2.34689 8.52453 2.11286 10.2274C1.87883 11.9303 2.08152 13.6647 2.70188 15.2677C3.32225 16.8707 4.33993
    18.2897 5.65931 19.3914C6.9787 20.4931 8.55649 21.2413 10.2445 21.5657C11.9324 21.8902 13.6752 21.7802 15.309
    21.2461C16.9428 20.7121 18.414 19.7715 19.5844 18.5127C20.7548 17.2539 21.5861 15.7182 22 14.0499C22.0504 13.8589
    22.0431 13.6572 21.9791 13.4704C21.915 13.2835 21.797 13.1198 21.64 12.9999ZM12.14 19.6899C10.4618 19.6781 8.82821
    19.1478 7.46301 18.1716C6.0978 17.1955 5.06768 15.8212 4.5137 14.237C3.95971 12.6528 3.90895 10.9361 4.36835
    9.32191C4.82776 7.70773 5.77487 6.27501 7.08001 5.21991V5.48991C7.08266 8.17839 8.15183 10.756 10.0529
    12.657C11.9539 14.5581 14.5315 15.6273 17.22 15.6299C17.9259 15.6325 18.63 15.5587 19.32 15.4099C18.6299 16.7154
    17.5965 17.8077 16.3312 18.5691C15.0659 19.3304 13.6167 19.7317 12.14 19.7299V19.6899Z" />
  </SvgIcon>
)

export default DarkIcon
