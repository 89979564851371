import sprintf from 'sprintf'
import axios, { getPayloadFromSuccess, getPayloadFromError } from '../../../helpers/axios'
import * as actionTypes from '../reducers/actionTypes'

// ------------------------------------
// Action fetch messages count
// ------------------------------------
export const API_MESSAGES_COUNT_URL = '/history/?page_size=1'

export const getMessagesCountAction = () => {
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .get(API_MESSAGES_COUNT_URL)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MESSAGES_COUNT,
      payload
    })
  }
}

// ------------------------------------
// Action fetch messages list
// ------------------------------------
export const API_MESSAGES_LIST_URL = '/history/?page_size=200&reminder=true'

export const getMessagesListAction = (params) => {
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .get(API_MESSAGES_LIST_URL, params)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MESSAGES_LIST,
      payload
    })
  }
}

// ------------------------------------
// Action fetch message done
// ------------------------------------
export const API_MESSAGE_DONE_URL = `/history/%d/done/`

export const doneMessageAction = (id) => {
  return (dispatch, getState) => {
    const url = sprintf(API_MESSAGE_DONE_URL, id)
    const payload = axios({ dispatch, getState })
      .put(url)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MESSAGE_DONE,
      payload
    })
  }
}

export default {
  getMessagesCountAction,
  getMessagesListAction,
  doneMessageAction
}
