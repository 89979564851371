/*
 * Type
 */
export const SUCCESS_TYPE = `success`

export const DANGER_TYPE = `danger`

/*
 * Action types
 */
export const COMMENT = 'COMMENT'
export const COMMENT_MOUNT = `${COMMENT}_MOUNT`
export const COMMENT_UNMOUNT = `${COMMENT}_UNMOUNT`
export const COMMENT_OPEN = `${COMMENT}_OPEN`
export const COMMENT_CLOSE = `${COMMENT}_CLOSE`

/*
 * Action creators
 */
export const mountCommentAction = payload => {
  return {
    type: COMMENT_MOUNT,
    payload
  }
}

export const unmountCommentAction = payload => {
  return {
    type: COMMENT_UNMOUNT,
    payload
  }
}

export const openCommentAction = payload => {
  return {
    type: COMMENT_OPEN,
    payload
  }
}

export const closeCommentAction = () => {
  return {
    type: COMMENT_CLOSE
  }
}
