const lang = {
  ru: {
    'Successfully deleted': 'Успешно удалено',
    'Failed on deleted': 'Ошибка при удалении',
    'Success': 'Успешно',
    'Username': 'Имя пользователя',
    'Sign In to your account': 'База данных\nGS1UzDataHub',
    'Remember me': 'Запомни меня',
    'Enter Password': 'Введите пароль',
    'Password': 'Пароль',
    'Sign In': 'Войти в систему',
    'Forgot Password?': 'Забыли пароль?',
    'Enter Username': 'Введите имя пользователя',
    'Fail': 'Ошибка',
    'Close': 'Закрыть',
    'active': 'Активный',
    'inactive': 'Неактивный',
    'Expiration date': 'Дата окончания',
    'User': 'Пользователь',
    'Group': 'Группа',
    'Class': 'Класс',
    'Sub class': 'Подкласс',
    'Attention': 'Внимание',
    'Last name': 'Фамилия',
    'First name': 'Имя',
    'Create user': 'Создание пользователя',
    'Edit user': 'Редактирование пользователя',
    'Create group': 'Создание группу',
    'Edit group': 'Редактировать группу',
    'Not all data is filled': 'Не все данные заполнены',
    'Exclude': 'Исключить',
    'Delete': 'Удалить',
    'Edit': 'Редактировть',
    'About': 'Информация',
    'Empty': 'Пустое',
    '%d-%d of %d': '%d-%d из %d',
    'Rows per page:': 'Количество на странице:',
    'Ooops, Item Not Found': 'По вашему запросу ничего не найдено',
    'Sent': 'Отправлено',
    'ID': 'Ид',
    'Name': 'Название',
    'Nomination': 'Наименование',
    'Activate': 'Активировать',
    'Member CCI': 'Член ТПП',
    'Are you sure you want to activate this invoice?': 'Вы уверены, что хотите активировать этот счет?',
    'Member CCI expire date': 'Член ТПП активен до',
    'Owner': 'Владелец',
    'Ownership': 'Форма собственности',
    'Status': 'Статус',
    'Registration date': 'Дата регистрации',
    'Created date': 'Дата создания',
    'Created by': 'Была создана',
    'Action': 'Действия',
    'No messages': 'Нет сообщении',
    'debtor': 'Задолжник',
    'Debtors': 'Задолжники',
    'Organisation is a member of the CCI': 'Это организация является членом ТТП',
    'Organisation is not a member of the CCI': 'Это организация не является членом ТТП',
    'less_30': 'Менше чем 30 дней',
    'User name': 'Аккаунт',
    'Full name': 'ФИО',
    'Filter': 'Фильтр',
    'Change Password': 'Изменить пароль',
    'Changed': 'Изменил',
    'excluded': 'Исключен',
    'Recovery': 'Восстановить',
    'Company info': 'Информация о компании',
    'Addresses and bank details info': 'Адреса и банковские реквизиты',
    'Bank info': 'Реквизиты',
    'Bank details info': 'Банковские реквизиты',
    'Other': 'Другое',
    'Submit': 'Сохранить',
    'Write comment': 'Написать комментарий',
    'Contacts': 'Контакты',
    'Contracts': 'Договора',
    'Agreement': 'Соглашение',
    'Finance': 'Финансы',
    'Histories': 'Истории',
    'Unknown': 'Неизвестно',
    'Not entered': 'Не введено',
    'Email not entered': 'Емаил не введено',
    'Company': 'Члены ассоциации',
    'Organization': 'Член ассоциации',
    'Companies': 'Компании',
    'Logout': 'Выход',
    'Email': 'Электронная почта',
    'Web site': 'Веб сайт',
    'Region': 'Область',
    'District': 'Район',
    'Street': 'Улица',
    'Fax': 'Факс',
    'Next': 'Далее',
    'Not printed': 'Не распечатанные',
    'Printed': 'Распечатанные',
    'Unpaid': 'Неоплаченные',
    'paid': 'Оплачено',
    'not_paid': 'Не оплачено',
    'partial_paid': 'Частично оплачено',
    'overpaid': 'Переплачено',
    'Previous': 'Назад',
    'Actual address': 'Фактический адрес',
    'Legal address': 'Юридический адрес',
    'Digital address': 'Электроный адрес',
    'Validity': 'Срок действия',
    'House number': 'Номер дома',
    'Post code': 'Почтовый индекс',
    'Exclude confirmation': 'Потвирждения искучении',
    'Are you sure you want to tempt this company?': 'Вы уверены что хотите искучить эту компании?',
    'Recovery confirmation': 'Потвирждения восстановления',
    'Are you sure you want to recovery this company?': 'Вы уверены что хотите восстановления эту компании?',
    'Products': 'Продукты',
    'Bank GTIN': 'Банк GTIN',
    'Product passport': 'Паспорт товара',
    'Bank name': 'Название банка',
    'Bank code': 'Код банка',
    'Bank account': 'Банковский реквизит',
    'Checking account': 'Р/с',
    'Company edit': 'Редактирование компании',
    'Create company': 'Создание компании',
    'Actions': 'Действия',
    'Type': 'Тип',
    'Image': 'Изображение',
    'Trade mark': 'ТМ',
    'Description': 'Описание',
    'Measure type': 'Единицы измерения',
    'Ean code': 'EAN-код',
    'Ean code type': 'Тип GTIN',
    'Packet name': 'Тип упаковки, кол-во',
    'Passport company': 'Паспорт предприятия',
    'Create Product': 'Создание продукта',
    'Create fee': 'Создание взноса',
    'Edit fee': 'Редактирование взноса',
    'Create contract': 'Регистрация договора',
    'Create invoice': 'Создание счет',
    'Edit Product': 'Редактирование продукта',
    'Packets': 'Упаковки',
    'Unit': 'Единичный',
    'one': 'Единичный',
    'lot': 'Множительный',
    'Lot': 'Множительный',
    'Search': 'Поиск',
    'Start date': 'Дата начала',
    'Start time': 'Время начала',
    'End time': 'Время окончания',
    'Clear': 'Очистить',
    'Apply': 'Применить',
    'End date': 'Дата оканчания',
    'Reminder date': 'Дата напоминания',
    'Download CSV format': 'Скачать как CSV',
    'GS1 code': 'GTIN',
    'Download': 'Скачать',
    'Information about contract': 'Информация о договора',
    'Delivery': 'Доставка',
    'Contract number': 'Номер договора',
    'Contract': 'Договор',
    'Type of payment': 'Вид взноса',
    'Paid': 'Оплачено',
    'Not paid': 'Не оплачено',
    'All financial history': 'Вся финансовая история',
    'Membership fee for the period s% to s%': 'Членский взнос за период c %s по %s',
    'Admission fee for the period s% to s%': 'Вступительный взнос за период c %s по %s',
    'Admission and membership fee for the period s% to s%': 'Вступительный взнос и членский взнос за период c %s по %s',
    'Partial paid': 'Частично оплачено',
    'Overpaid': 'Переплачено',
    'Create bank': 'Создание банка',
    'Edit bank': 'Редактирование банка',
    'Amount': 'Сумма',
    'Pay': 'Оплатить',
    'Merge': 'Обьеденить',
    'TIN': 'ИНН',
    'Total price': 'Сумма оплаты',
    'yes': 'да',
    'no': 'нет',
    'Org type': 'Категории организации',
    'Delivery type': 'Способ доставки',
    'Edit contract': 'Редактировать договор',
    'Contract date': 'Дата договора',
    'Date': 'Дата',
    'Number date': 'Номер договора',
    'Company name': 'Наименование организации',
    'Company type': 'Категория',
    'City': 'Город',
    'No': 'Нет',
    'Bank': 'Банк',
    'Unpay': 'Вернуть деньги',
    'Yes': 'Да',
    'Invoice for payment': 'Счёт к оплате',
    'Invoice for payment with stamp': 'Счёт к оплате с печатю',
    'Big': 'Крупное',
    'big': 'Крупное',
    'Small': 'Малое',
    'small': 'Малое',
    'Family': 'Семейное',
    'family': 'Семейное',
    'Beekeepers': 'Пчеловоды',
    'beekeepers': 'Пчеловоды',
    'Handicap': 'Инвалиды',
    'handicap': 'Инвалиды',
    'Price': 'Цена',
    'Maker': 'Регистратор',
    'Recipient': 'Представитель',
    'History': 'История',
    'Phone': 'Телефон',
    'Photo': 'Фото',
    'Single package': 'Ед. Упаковка',
    'Title': 'Заголовок',
    'Contract detail': 'Детали договора',
    'a day ago': 'Дне назадь',
    'Add': 'Добавить',
    'Change password': 'Изменить пароль',
    'Remove': 'Удалить',
    'Global prefix of company(GCP)': 'Глобальный префикс компании (GCP)',
    'GLN enterprises': 'Глобальный идентификационный номер (GLN)',
    'OKED': 'ОКЭД',
    'OKONKH': 'ОКОНХ',
    'Number': 'Номер',
    'Type of activity': 'Вид деятельности',
    'Type of activity according to OKED': 'Вид деятельности по ОКЭД',
    'Type of activity according to KVED': 'Вид деятельности по КВЭД',
    'Economic activity type': 'Вид экономической деятельности',
    'Leader': 'Руководитель',
    'Make paid': 'Сделать оплаченный',
    'Are you sure you want to make paid this contract?': 'Вы действительно хотите сделать этот договор оплаченный?',
    'Responsible for work': 'Ответственный по работе',
    'Leader phone number': 'Телефон руководителя',
    'Phone responsible for work': 'Телефон ответственного по работе',
    'Email responsible for work': 'Эл.почта ответственного по работе',
    'Accountant': 'Бухгалтер',
    'Accountant\'s phone number': 'Телефон бухгалтера',
    'Record': 'Запись',
    'Remember date': 'Дата уведомлении',
    'Save': 'Сохранить',
    'News': 'Новости',
    'Bank data': 'Банковские данные',
    'Content': 'Содержпние',
    'Publish date': 'Дата публикации',
    'Is active': 'Активный',
    'Who added': 'Кто добавил',
    'Statistic': 'Статистика',
    'Search by system': 'Поиск',
    'Active': 'Активные',
    'Less than 30': '30 дней до окончания',
    'All': 'Все',
    'Parent': 'Родитель',
    'Users': 'Пользователи',
    'Package': 'Упаковка',
    'Package text': 'Тип упаковки, кол-во, ед-ца измерения',
    'Excluded': 'Исключенные',
    'Debtor': 'Должник',
    'Year': 'Год',
    'Jan': 'Январь',
    'Feb': 'Февраль',
    'Mar': 'Март',
    'Apr': 'Апрель',
    'May': 'Май',
    'Jun': 'Июнь',
    'Jul': 'Июль',
    'Aug': 'Август',
    'Sep': 'Сентябрь',
    'Oct': 'Октябрь',
    'Nov': 'Ноябрь',
    'Dec': 'Декабрь',
    'Mo': 'Пн',
    'Tu': 'Вт',
    'We': 'Ср',
    'Th': 'Чт',
    'Fr': 'Пт',
    'Sa': 'Сб',
    'Su': 'Вс',
    'On': 'Включен',
    'Off': 'Выключен',
    'Action time': 'Время действия',
    'Print invoice': 'Распечатать счет',
    'Print invoice with stamp': 'Распечатать счет с печатью',
    'Print payment': 'Распечатать оплату',
    'Print payment with stamp': 'Распечатать оплату с печатью',
    'Print with stamp': 'Распечатать с печатью',
    'Print certificate': 'Распечатать свидетельство',
    'Print': 'Распечатать',
    'Value': 'Значение',
    'Values': 'Значении',
    'SMS settings': 'SMS настройки',
    'Settings': 'Настройки',
    'Section': 'Раздел',
    'Text': 'Текст',
    'Active until: ': 'До: ',
    'An organization like this inn already exists.': 'Организация с таким ИНН уже существует.',
    'Invoice number': 'Номер счета',
    'Invoice date': 'Дата счета',
    'Admission fee': 'Вступительный взнос',
    'admission': 'Вступительный',
    'Membership fee': 'Членский взнос',
    'membership': 'Членский',
    'None': 'Не указан',
    'Begin date': 'Дата начала',
    'Is paid': 'Оплачено',
    'Invoice history': 'История счетов',
    'Total': 'Общая сумма',
    'Upload': 'Загрузить',
    'Edit company': 'Редактировать компанию',
    'User group': 'Пользовательская группа',
    'Postcode': 'Почтовый индекс',
    'Not uploaded': 'Не загружено',
    'Personally': 'Наручно',
    'personally': 'Наручно',
    'Email post': 'Эл.почта',
    'Messenger': 'Мессенджер',
    'Permissions': 'Права доступа',
    'Post': 'Почта',
    'post': 'Почта',
    'fax': 'Факс',
    'Comment': 'Комментарий',
    'Monday': 'Понедельник',
    'Tuesday': 'Вторник',
    'Wednesday': 'Среда',
    'Thursday': 'Четверг',
    'Friday': 'Пятница',
    'Saturday': 'Суббота',
    'Sunday': 'Воскресенье',
    'Expired active date': 'Истекла активная дата',
    'Celebration': 'Праздник',
    'Message type': 'Тип сообщении',
    'Group name': 'Имя группы',
    'Groups': 'Группы',
    'Packages count': 'Количество упаковок',
    'Packages': 'Упаковки',
    'Invoices': 'Счета',
    'Invoice': 'Счет',
    'selected': 'выбранные',
    'Not specified': 'Не указано',
    'For all': 'Для всех видов',
    'Messages': 'Сообщения',
    'Fee': 'Взнос',
    'Make printed': 'Сделать распечатанным',
    'Finance statistic by year (%s)': 'Годовой оборот. Итого %s сумов.',
    'Statistic by status %d': 'Статусы подписок(%d)',
    'Statistic by category %d': 'Предприятия (%d)',
    'Statistic by year': 'Новые подписки(по годам)',
    'Counts of organizations joined in %d. All %d': 'Новые подписки по месяцам к %d году(%d)',
    'Are you sure you want to make paid this invoice?': 'Вы уверены, что хотите сделать оплаченным этот счет?',
    'Product name': 'Название продукта',
    'Product type': 'Вид',
    'Product class type': 'Классификатор видов и подвидов продукции',
    'Sub product type': 'Подвид',
    'Payment type': 'Тип оплаты',
    'Create product type': 'Создание классификатор видов',
    'Edit product type': 'Редактирование классификатор видов',
    'Activate date': 'Дата активации',
    'Invoice sum': 'Сумма счета',
    'Membership period': 'Период членства',
    'Debtors for 3 month': 'Должники за 3 месяца',
    'Debtors for 6 month': 'Должники за 6 месяца',
    'Debtors for year': 'Должники за год',
    'Total sum': 'Общая сумма',
    'Statistic by month': 'Статистика подписок новых компании по месяцам',
    'Statistic by finance': 'Финансовая статистика',
    'Statistic by status': 'Статистика по статусам компании',
    'Statistic by category': 'Статистика по размеру предприятия',
    'Sum': 'Сумма',
    'Count': 'Количество',
    'Finance statistic for %d.xls': 'Статистика финансов для %d.xls',
    'Notification': 'Уведомление',
    'Regions': 'Область',
    'Districts': 'Районы',
    'System': 'Система',
    'Billing': 'Биллинг',
    'Organization: %s "%s"': 'Организации: %s "%s"',
    'Author': 'Автор',
    'Published date': 'Дата пбликации',
    'Page: ': 'Страница: ',
    'Cancel': 'Отмена',
    'Confirm': 'Подтвердить',
    'Delete confirmation': 'Подтвердить удаление',
    'Are you sure to delete this item?': 'Вы уверены, что хотите удалить этот элемента?',
    'Set printed products': 'Сделать распечатанном!',
    'Are you sure set printed products?': 'Вы уверены что хотите сделать продукты распечатанном?',
    'Make send': 'Сделать отправленом!',
    'Are you sure you want to send this invoice?': 'Вы уверены что хотите сделать счет отправленим?',

    'Are tou sure to remove type of activity CTEA?': 'Вы уверены что хотите удалить этот вид деятельности по КВЭД?',
    'Are tou sure to remove this contract?': 'Вы уверены что хотите удалить этот договор?',
    'Login warning': 'Внимание! Если Вы не являетесь сотрудником GS1 Uzbekistan немедленно покиньте эту страницу.',
    'Can can_crud_for_dept_company product': 'CRUD продуктов для исключенных преприядтий.',
    'Add Statistics': 'Добавить Статистику',
    'Statistic by Product': 'Статистика по кол-во продуктов',
    'Can add group': 'Создание группы',
    'Can change group': 'Редактирование группы',
    'Can delete group': 'Удаление группы',
    'Can view group': 'Просмотр групп',
    'Can list address': 'Просмотр списка адресов компаний',
    'Can retrieve address': 'Просмотр детали адераса компаний',
    'Can create bank': 'Создание банка',
    'Can destroy bank': 'Удаление банка',
    'Can list bank': 'Просмотр списка банков',
    'Can retrieve bank': 'Просмотр детали банка',
    'Can update bank': 'Редактирование банка',
    'Can create branch': 'Создание филиала',
    'Can destroy branch': 'Удаление филиала',
    'Can list branch': 'Просмотр списка филиалов',
    'Can retrieve branch': 'Просмотр детали филиала',
    'Can update branch': 'Редактирование филиала',
    'Can create business activity': 'Создание КВЭД',
    'Can destroy business activity': 'Удаление КВЭД',
    'Can list business activity': 'Просмотр списка КВЭД',
    'Can retrieve business activity': 'Просмотр детали КВЭД',
    'Can update business activity': 'Редактирование КВЭД',
    'Can activate company': 'Активация компании',
    'Can clear_signatures company': 'Удаление подписей компании',
    'Can create company': 'Создание компании',
    'Can deactivate company': 'Деактивация компании',
    'Can destroy company': 'Удаление компании',
    'Can list company': 'Списко компании',
    'Can merge company': 'Слияние компаний',
    'Can retrieve company': 'Просмотр деталий компаний',
    'Can statistics company': 'Просмотр статистики по компаниям',
    'Can update company': 'Редактирование компаний',
    'Can create district': 'Создание района',
    'Can destroy district': 'Удаление района',
    'Can list district': 'Просмотр списка районов',
    'Can retrieve district': 'Просмотр деталий района',
    'Can update district': 'Редактирование района',
    'Can create economic activity': 'Создание ОКЭД',
    'Can destroy economic activity': 'Удаление ОКЭД',
    'Can list economic activity': 'Просмотр списка ОКЭД',
    'Can retrieve economic activity': 'Просмотр детайлей ОКЭД',
    'Can create ownership': 'Создание формы собственности',
    'Can destroy ownership': 'Удаление формы собственности',
    'Can list ownership': 'Просмотр списка формы собственности',
    'Can retrieve ownership': 'Просмотр детали формы собственности',
    'Can update ownership': 'Редактирование формы собственности',
    'Can create region': 'Создание области',
    'Can destroy region': 'Удаление области',
    'Can list region': 'Просмотр списка обласлей',
    'Can retrieve region': 'Просмотр детали области',
    'Can update region': 'Редактирование области',
    'Can add agreement': 'Создание соглашения',
    'Can change agreement': 'Редактирование соглашения',
    'Can delete agreement': 'Удаление соглашения',
    'Can view agreement': 'Просмотр деталей соглашения',
    'Can create category': 'Создание категории договора',
    'Can destroy category': 'Удаление категории договора',
    'Can list category': 'Просмотр списка категорий договора',
    'Can retrieve category': 'Просмотр детали категории договора',
    'Can update category': 'Редактирование категории договора',
    'Can activate contract': 'Активаиця договора',
    'Can comment contract': 'Созадние коментайрия договора',
    'Can create contract': 'Создание договора',
    'Can deactivate contract': 'Деактивация договора',
    'Can destroy contract': 'Удаление договора',
    'Can list contract': 'Просмотр списка договора',
    'Can paid contract': 'Оплата договора',
    'Can retrieve contract': 'Просмотр детали договора',
    'Can un_pay_invoice contract': 'Сделать договор не оплаченным',
    'Can update contract': 'Редактирование договора',
    'Can create fee': 'Создание взноса',
    'Can destroy fee': 'Удаление взноса',
    'Can list fee': 'Просмотр списка взносов',
    'Can retrieve fee': 'Просмотр детали взноса',
    'Can update fee': 'Редактирование взноса',
    'Can activate invoice': 'Активация счета',
    'Can create invoice': 'Создание счета',
    'Can destroy invoice': 'Удаление счета',
    'Can list invoice': 'Просмотр списка счета',
    'Can paid invoice': 'Оплачивать счет',
    'Can retrieve invoice': 'Просмотр детали счета',
    'Can send invoice': 'Отправка счета',
    'Can update invoice': 'Редактирование счета',
    'Can add f store': 'Возможность заливать файлы',
    'Can change_password user': 'Изменять пароль пользователей',
    'Can create user': 'Созадние пользователей',
    'Can destroy user': 'Удаление пользователей',
    'Can list user': 'Просмотр списка пользователей',
    'Can retrieve user': 'Просмотр деталей пользователей',
    'Can update user': 'Редактирование пользователей',
    'Can create city': 'Создание города',
    'Can destroy city': 'Удаление городов',
    'Can list city': 'Просмотр списка городов',
    'Can retrieve city': 'Просмотр детали города',
    'Can update city': 'Редактирование договора',
    'Can create country': 'Создание страны',
    'Can destroy country': 'Удаление страны',
    'Can list country': 'Просмотр списка стран',
    'Can retrieve country': 'Просмотр детали стран',
    'Can update country': 'Редактирование стран',
    'Can confirm product': 'Подтверждать продукты',
    'Can create product': 'Создавать продукты',
    'Can destroy product': 'Удалять продукты',
    'Can list product': 'Просмотр списка продуктов',
    'Can retrieve product': 'Просмотр деталей продукта',
    'Can update product': 'Редактирование продукта',
    'Can create product type': 'Создание класификатора продуктов',
    'Can destroy product type': 'Удаление класификатора продуктов',
    'Can list product type': 'Просмотр списка класификатора продуктов',
    'Can retrieve product type': 'Просмотр детали класификатора продуктов',
    'Can update product type': 'Редактирование класификатора продуктов',
    'Can statistic_product user': 'Статистика по продуктам',
    'Can finance_statistic user': 'Финансовая статистика',
    'Can statistic_api_request user': 'API Статистика',
    'Can statistic_company_category user': 'Статистика по категориям компаний',
    'Can statistic_company_status user': 'Статистика по статусам компаний',
    'Can statistic_company_year user': 'Статистика по годам компаний',
    'Can statistic_company_month user': 'Статистика по месяцам компаний',
  }
}

export default lang
