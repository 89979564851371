import { injectReducers } from '../../store/reducers'
import * as ROUTES from '../../constants/routes'
import AppLayout from '../../components/Layouts/AppLayout'
import { AsyncComponent } from '../../components/Layouts/RouterLayout'

const getGroupsContainer = store =>
  import(/* webpackChunkName: "groups" */ './reducers')
    .then(module => injectReducers(store, module.default))
    .then(() => import(/* webpackChunkName: "groups" */ './containers/GroupsContainer'))
    .then(module => module.default)

export default (store) => ([
  {
    layout: AppLayout,
    exact: true,
    path: [
      ROUTES.GROUPS_LIST_PATH
    ],
    component: AsyncComponent(() => getGroupsContainer(store)),
  }
])
