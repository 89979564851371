import React from 'react'
import { BehaviorSubject } from 'rxjs'
import PropTypes from 'prop-types'
import { compose, setDisplayName, pure, componentFromStream, createEventHandler } from 'recompose'
import withStyles from '@material-ui/core/styles/withStyles'
import Toolbar from '@material-ui/core/Toolbar'
import MUIAppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import Slide from '@material-ui/core/Slide'
import MenuIcon from '@material-ui/icons/Menu'
import t from '../../helpers/translate'
import { getStorage, setStorage } from '../../helpers/localStorage'
import Profile from '../Profile'
import Menu from '../Menu'
import SearchHeader from '../SearchHeader'
import BadgeMessages from '../BadgeMessages/BadgeMessages'
import PermissionWrapper from '../PermissionWrapper'
import ThemeSelect from '../ThemeSelect/ThemeSelect'

const styles = theme => ({
  root: {
    height: 'inherit'
  },
  toolbar: {
    minHeight: 54,
  },
  gutters: {
    paddingLeft: 12,
    paddingRight: 12
  },
  body: {
    paddingTop: 54,
    minHeight: '100vh',
    paddingLeft: '12px',
    paddingRight: '12px'
  },
  center: {
    width: '100%'
  },
  badgeMessages: {
    marginRight: 20
  },
  content: {
    paddingTop: 24,
    paddingRight: 72
  },
  fullcontent: {
    paddingTop: 24,
    margin: '0 auto'
  },
  theme: {
    textAlign: 'right'
  }
})

const enhance = compose(
  withStyles(styles),
  pure,
  setDisplayName('AppBar')
)

const AppBar = componentFromStream(props$ => {
  const { stream: onClick$, handler: onClick } = createEventHandler()
  const initialState = { left: getStorage('left', true) }
  const dispatch$ = new BehaviorSubject(initialState)

  onClick$
    .subscribe(() => {
      const value = dispatch$.getValue()
      const state = !value.left

      setStorage('left', state)

      dispatch$.next({ ...value, left: state })
    })

  return props$.combineLatest(
    dispatch$.asObservable(),
    ({ classes, ...props }, state) => {
      return (
        <div className={classes.root}>
          <MUIAppBar color="primary" position="fixed">
            <Toolbar classes={{ root: classes.toolbar, gutters: classes.gutters }}>
              <Grid
                container={true}
                alignItems="center"
                justify="space-between"
                spacing={0}>
                <Grid item={true} xs={2}>
                  <IconButton color="inherit" aria-label="Menu" onClick={onClick}>
                    <MenuIcon />
                  </IconButton>
                </Grid>

                <Grid
                  container={true}
                  justify="center"
                  item={true}
                  xs={7}>
                  <Grid className={classes.center}>
                    <SearchHeader placeholder={t('Search by system')} />
                  </Grid>
                </Grid>
                <Grid item={true} xs={3}>
                  <Grid justify="flex-end" alignItems="center" container={true}>
                    <ThemeSelect />
                    <PermissionWrapper accesses={['list_history']}>
                      <div className={classes.badgeMessages}>
                        <BadgeMessages />
                      </div>
                    </PermissionWrapper>
                    <Profile profile={props.profile} logout={props.logout} />
                  </Grid>
                </Grid>
              </Grid>
            </Toolbar>
          </MUIAppBar>

          <Grid
            className={classes.body}
            container={true}
            justify="space-between">
            {state.left && (
              <Grid item={true} xs={2}>
                <Slide direction="right" in={state.left}>
                  <Menu active={props.active} />
                </Slide>
              </Grid>
            )}
            <Grid
              className={state.left ? classes.content : classes.fullcontent}
              container={true}
              item={true}
              xs={state.left ? 10 : 12}>
              <Grid className={classes.center}>
                {props.children}
              </Grid>
            </Grid>
          </Grid>
        </div>
      )
    })
})

AppBar.propTypes = {
  children: PropTypes.any,
  classes: PropTypes.object.isRequired,
  profile: PropTypes.object,
  logout: PropTypes.func.isRequired,
  permissions: PropTypes.array,
  active: PropTypes.string.isRequired,
}

export default enhance(AppBar)
