/*
 * Action types
 */
export const THEME = 'THEME'
export const SET_THEME = `SET_${THEME}`

/*
 * Action creators
 */
export const setThemeAction = payload => {
  return {
    type: SET_THEME,
    payload
  }
}
