import { compose } from 'ramda'
import { withRouter } from 'react-router-dom'
import { pure } from 'recompose'
import UserIsAuthenticated from '../../../permissions/UserIsAuthenticated'
import StatisticDetail from '../components/StatisticDetail'

export default compose(
  UserIsAuthenticated,
  withRouter,
  pure
)(StatisticDetail)
