import { injectReducers } from '../../store/reducers'
import * as ROUTES from '../../constants/routes'
import AppLayout from '../../components/Layouts/AppLayout'
import { AsyncComponent } from '../../components/Layouts/RouterLayout'

const getUserContainer = store =>
  import(/* webpackChunkName: "users" */ './reducers')
    .then(module => injectReducers(store, module.default))
    .then(() => import(/* webpackChunkName: "users" */ './containers/UserContainer'))
    .then(module => module.default)

export default (store) => ([
  {
    layout: AppLayout,
    exact: true,
    path: [
      ROUTES.USER_LIST_PATH
    ],
    component: AsyncComponent(() => getUserContainer(store)),
  }
])
