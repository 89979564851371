import React from 'react'
import PropTypes from 'prop-types'
import { compose, createEventHandler, mapPropsStream, pure } from 'recompose'
import { connect } from 'react-redux'
import withStyles from '@material-ui/core/styles/withStyles'
import SwipeableDrawer from '@material-ui/core/es/SwipeableDrawer/SwipeableDrawer'
import { __, curry, path, prop } from 'ramda'
import List from '@material-ui/core/es/List/List'
import DoneIcon from '@material-ui/icons/Done'
import ListItem from '@material-ui/core/es/ListItem/ListItem'
import ListItemText from '@material-ui/core/es/ListItemText/ListItemText'
import Typography from '@material-ui/core/es/Typography/Typography'
import Toolbar from '@material-ui/core/es/Toolbar/Toolbar'
import ListItemSecondaryAction from '@material-ui/core/es/ListItemSecondaryAction/ListItemSecondaryAction'
import IconButton from '@material-ui/core/es/IconButton/IconButton'
import sprintf from 'sprintf'
import { Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/es/CircularProgress/CircularProgress'
import t from '../../helpers/translate'
import { closeMessagesAction } from '../BadgeMessages/reducers/actions'
import T from '../T'
import { MESSAGES_LIST } from '../../constants/states'
import { getDataFromState } from '../../helpers/get'
import * as DATE_FORMAT from '../../constants/dateFromat'
import { format } from '../../helpers/dateFormat'
import * as ROUTES from '../../constants/routes'
import { getMessagesListAction, doneMessageAction, getMessagesCountAction } from './actions/messages'

const styles = theme => ({
  root: {
    minWidth: 400,
    width: '100%',
    height: '100%',
    background: theme.table.backgroundColor,
    '& *': {
      color: theme.table.textColor
    }
  },
  loading: {
    textAlign: 'center'
  }
})

const mapStateToProps = state => ({
  open: prop('messagebar', state),
  messages: getDataFromState(MESSAGES_LIST, state)
})
const mapDispatchToProps = {
  close: closeMessagesAction,
  getMessagesListAction,
  doneMessageAction,
  getMessagesCountAction
}
const getDate = compose(
  curry(format)(__, DATE_FORMAT.ISO_FORMAT),
  prop('reminderDate')
)

const enhance = compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  mapPropsStream(props$ => {
    const { handler: onDone, stream: onDone$ } = createEventHandler()

    onDone$
      .withLatestFrom(props$)
      .subscribe(([id, props]) => {
        props
          .doneMessageAction(id).then(() => props.getMessagesCountAction()).then(() => props.getMessagesListAction())
      })

    props$
      .distinctUntilChanged(null, prop('open'))
      .filter(prop('open'))
      .subscribe(props => {
        props.getMessagesListAction()
      })

    return props$.combineLatest(props => ({
      ...props,
      onDone: id => onDone(id)
    }))
  }),
  pure
)

const Messages = ({ classes, history, close, open, messages, onDone }) => {
  const list = path(['data', 'results'], messages)
  const loading = prop('loading', messages)

  return (
    <SwipeableDrawer
      anchor="right"
      className={classes.block}
      open={open}
      onClose={close}
      onOpen={() => {}}
    >
      <div className={classes.root}>
        <Toolbar>
          <Typography component="h2" color="inherit">
            <T>Messages</T>
          </Typography>
        </Toolbar>
        {!loading && list && (<List>
          {list.map(item => {
            const companyId = path(['contentObject', 'id'], item)
            const firstName = path(['user', 'firstName'], item)
            const lastName = path(['user', 'lastName'], item)
            const id = prop('id', item)
            const infoUrl = sprintf(ROUTES.COMPANY_DETAIL_TAB_URL, companyId, 'about') + `?id=${companyId}`
            return (
              <ListItem key={id}>
                <ListItemText secondary={prop('text', item)}>
                  <div>
                    <Link to={infoUrl} onClick={close}>
                      {path(['contentObject', 'name'], item)}
                    </Link>
                    <br />
                    <span>{getDate(item)} - {firstName} {lastName}</span>
                  </div>
                </ListItemText>
                <ListItemSecondaryAction>
                  <IconButton aria-label={t('Done')} onClick={() => onDone(id)}>
                    <DoneIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>)
          }, list)}
        </List>)}
        {!loading && list && !list.length && (<ListItem>
          <T>No messages</T>
        </ListItem>)}
        {loading && (
          <div className={classes.loading}>
            <CircularProgress size={50} />
          </div>
        )}
      </div>
    </SwipeableDrawer>
  )
}

Messages.propTypes = {
  classes: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  messages: PropTypes.any,
  open: PropTypes.bool,
}

export default enhance(Messages)
