// We only need to import the modules necessary for initial render
import { Switch } from 'react-router'
import React from 'react'
import RouteWithLayout from '../components/Layouts/RouterLayout'
import Dashboard from './Dashboard'
import Auth from './Auth'
import User from './User'
import Groups from './Groups'
import Error from './Error'
import Company from './Company'
import Common from './Common'
import Contract from './Contract'
import News from './News'
import Fee from './Fee'
import Statistic from './Statistic'
import Message from './Message'
import Product from './Product'
import District from './District'
import Region from './Region'
import Activity from './BusinessActivities'
import EconomicActivity from './EconomicActivities'
import ProductType from './ProductType'
import Ownership from './Ownership'
import PdfAgreement from './PdfAgreement'
import PdfContract from './PdfContract'
import Banks from './Banks'
import Settings from './Settings'
import BusinessType from './BusinessType'
import Country from './Country'
import City from './City'
import Branch from './Branch'

export default (store) => {
  const routes = [
    ...Auth(store),
    ...User(store),
    ...Groups(store),
    ...Dashboard(store),
    ...Common(store),
    ...Company(store),
    ...Contract(store),
    ...News(store),
    ...Fee(store),
    ...Statistic(store),
    ...Message(store),
    ...Settings(store),
    ...Product(store),
    ...District(store),
    ...Region(store),
    ...Country(store),
    ...City(store),
    ...Ownership(store),
    ...PdfAgreement(store),
    ...PdfContract(store),
    ...Activity(store),
    ...EconomicActivity(store),
    ...ProductType(store),
    ...Banks(store),
    ...BusinessType(store),
    ...Branch(store),
    ...Error(store),
  ]

  return (
    <div style={{ height: '100%' }}>
      <Switch>
        {routes.map((route, i) => (
          <RouteWithLayout key={i} {...route} />
        ))}
      </Switch>
    </div>
  )
}
