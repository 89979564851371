import { createMuiTheme } from '@material-ui/core/styles'

export const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#233649',
      contrastText: '#fff',
    },
    secondary: {
      main: '#e65000'
    }
  },
  link: {
    color: '#233649'
  },
  typography: {
    useNextVariants: true,
  },
  error: {
    color: '#F44336'
  },
  overrides: {
    MuiCheckbox: {
      colorPrimary: {
        color: '#aaa',
        '&$checked': {
          color: '#ff8900',
        },
      },
    },
    MuiPopover: {
      paper: {
        background: '#fff'
      }
    }
  },
  components: {
    input: {
      autocomplete: '#fff'
    },
    popover: {
      background: '#fff',
      color: '#000'
    },
    snackbar: {
      info: '#42a5f5',
      warning: '#f8c200',
      success: '#28bebd',
      danger: '#EF5350'
    },
    buttons: {
      submitButton: {
        color: '#233649'
      },
      actionButton: {
        background: '#e0e0e0',
        hoverBackground: '#c5c5c5',
        color: '#233649'
      }
    },
    menu: {
      active: '#F6882D'
    },
    tab: {
      indicator: '#F6882D'
    },
    table: {
      contentBgColor: '#fff',
      color: '#000'
    },
    confirmDialog: {
      backgroundColor: '#F44336',
      text: '#fff',
      hover: {
        backgroundColor: '#E53935'
      }
    }
  },
  body: {
    background: '#F1F1F1',
    color: '#000'
  },
  table: {
    headerTextColor: '#000',
    borderColor: '#eee',
    headerIconColor: '#fff',
    hoverBackgroundColor: '#e7e7e7',
    textColor: '#000',
    backgroundColor: '#fff'
  },
})

export const muiThemeDark = createMuiTheme({
  palette: {
    primary: {
      main: '#3C4D5E',
    },
    type: 'dark'
  },
  link: {
    color: '#fff'
  },
  typography: {
    useNextVariants: true,
  },
  error: {
    color: '#F44336'
  },
  overrides: {
    MuiCheckbox: {
      colorPrimary: {
        color: '#fff',
        '&$checked': {
          color: '#ff8900',
        },
      },
    },
    MuiPopover: {
      paper: {
        background: '#445465',
        color: '#fff'
      }
    },
    MuiDialog: {
      paper: {
        background: '#445465',
        color: '#fff'
      }
    }
  },
  components: {
    input: {
      autocomplete: '#445465'
    },
    popover: {
      background: '#445465',
      color: '#fff'
    },
    snackbar: {
      info: '#42a5f5',
      warning: '#f8c200',
      success: '#28bebd',
      danger: '#EF5350'
    },
    buttons: {
      submitButton: {
        color: '#fff'
      },
      actionButton: {
        background: '#2f4254',
        hoverBackground: '#394d60',
        color: '#fff'
      }
    },
    menu: {
      active: '#F6882D'
    },
    tab: {
      indicator: '#F6882D'
    },
    table: {
      contentBgColor: '#445465',
      color: '#fff'
    },
    confirmDialog: {
      backgroundColor: '#F44336',
      text: '#fff',
      hover: {
        backgroundColor: '#E53935'
      }
    }
  },
  body: {
    background: '#233649',
    color: '#fff'
  },
  table: {
    borderColor: '#748190',
    headerTextColor: 'rgba(255, 255, 255, 0.5)',
    headerIconColor: '#fff',
    textColor: '#fff',
    hoverBackgroundColor: '#3a495a',
    backgroundColor: '#445465'
  },
})
