import AppLayout from '../../components/Layouts/AppLayout'
import * as ROUTES from '../../constants/routes'
import SettingsContainer from './containers/SettingsContainer'

export default (store) => ([
  {
    layout: AppLayout,
    exact: true,
    path: ROUTES.SETTINGS_PATH,
    component: SettingsContainer
  }
])
