import { isEmpty, isNil, path } from 'ramda'
import { routerActions } from 'react-router-redux'
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import { hasAccess } from '../components/PermissionWrapper'

export default (accesses = [], redirectUrl = '/') => connectedRouterRedirect({
  redirectPath: state => redirectUrl,
  authenticatedSelector: state => {
    const permissions = path(['permissions', 'data'], state)
    return hasAccess(accesses, permissions) || isEmpty(accesses) || isNil(accesses)
  },
  redirectAction: routerActions.replace,
  wrapperDisplayName: 'HasPermission'
})
