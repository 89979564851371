import React from 'react'
import PropTypes from 'prop-types'
import { compose, pure } from 'recompose'
import withStyles from '@material-ui/core/styles/withStyles'
import CssBaseline from '@material-ui/core/CssBaseline'
import PageLoading from '../PageLoading'
import Snackbar from '../Snackbar'
import Comment from '../Comment'
import ConfirmDialog from '../ConfirmDialog'
import { getDefaultProps } from '../../helpers/app'
import PermissionWrapper from '../PermissionWrapper'
import Messages from '../Messages/Messages'

const inputNumberSelector = 'input[type="number"]::-webkit-inner-spin-button, ' +
  'input[type="number"]::-webkit-outer-spin-button, ' +
  'input[type="number"]::-webkit-search-cancel-button, ' +
  'input[type="number"]::-webkit-search-results-button'

const styles = theme => ({
  '@global': {
    html: {
      height: '100%',
      width: '100%',
      fontSize: 14,
      fontFamily: '"Roboto", sans-serif'
    },
    [inputNumberSelector]: {
      '-webkit-appearance': 'none',
      'margin': '0'
    },
    body: {
      backgroundColor: theme.body.background,
      color: theme.body.color,
      height: '100%',
      width: '100%',
      '& .h1, & .h2, & .h3, & .h4, & .h5, & .h6, & h1, & h2, & h3, & h4, & h5, & h6': {
        color: theme.palette.primary.main
      },
      '& h1, & h2, & h3, & h4, & h5, & h6': {
        fontWeight: '300'
      },
      '& a:link, & a:visited': {
        transition: 'all .5s ease'
      },
      '& .divider, & hr': {
        marginTop: 10,
        marginBottom: 10,
        height: 1,
        width: '100%',
        background: '#E3ECF7'
      },
      '& input:-webkit-autofill': {
        '-webkit-box-shadow': `0 0 0 1000px ${theme.components.input.autocomplete} inset`
      }
    },
    a: {
      color: theme.palette.primary.main,
      textDecoration: 'none'
    },
    hr: {
      border: 0,
      borderTop: '1px solid #eee',
    }
  },
  page: {
    height: '100%'
  }
})

const enhance = compose(
  withStyles(styles),
  pure
)

const BaseLayout = ({ classes, ...props }) => (
  <div className={classes.page}>
    <CssBaseline />
    <PageLoading />
    {props.children && React.cloneElement(props.children, { app: getDefaultProps(props) })}
    <Snackbar />
    <Comment />
    <ConfirmDialog />
    <PermissionWrapper accesses={['list_history']}>
      <React.Fragment>
        <Messages history={history} />
      </React.Fragment>
    </PermissionWrapper>
  </div>
)

BaseLayout.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
}

export default enhance(BaseLayout)
