import { head, toUpper } from 'ramda'
import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Avatar from '@material-ui/core/Avatar'

const styles = {}

const enhance = withStyles(styles)

const AvatarOrFirstLatter = ({ classes, ...props }) => {
  if (props.src) {
    return (
      <Avatar src={props.src} />
    )
  }

  return (
    <Avatar title={props.username}>
      {toUpper(head(props.username))}
    </Avatar>
  )
}

AvatarOrFirstLatter.propTypes = {
  classes: PropTypes.object.isRequired,
  username: PropTypes.string.isRequired,
  src: PropTypes.string,
}

export default enhance(AvatarOrFirstLatter)
