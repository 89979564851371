import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/es/Paper/Paper'
import List from '@material-ui/core/es/List/List'
import ListItem from '@material-ui/core/es/ListItem/ListItem'
import ListItemText from '@material-ui/core/es/ListItemText/ListItemText'
import Divider from '@material-ui/core/es/Divider/Divider'
import Typography from '@material-ui/core/es/Typography/Typography'
import { Link } from 'react-router-dom'
import { compose, pure } from 'recompose'
import withStyles from '@material-ui/core/styles/withStyles'
import T from '../../../components/T'
import AppBar from '../../../components/AppBar'
import * as ROUTES from '../../../constants/routes'

const styles = theme => ({
  root: {
    marginBottom: 20,
    background: theme.table.backgroundColor,
    '& *': {
      color: theme.table.textColor,
    },
    '&:last-child': {
      marginBottom: 0
    }
  }
})

const enhance = compose(
  withStyles(styles),
  pure
)

const StatisticDetail = props => {
  const { classes } = props
  return (
    <AppBar active={ROUTES.STATISTIC} {...props.app}>
      <Paper className={classes.root} elevation={1}>
        <List className={classes.root}>
          <ListItem>
            <ListItemText><T>Statistic</T></ListItemText>
          </ListItem>
          <Divider component="li" />
          <ListItem>
            <Typography color="textSecondary" variant="caption">
              <Link to={ROUTES.STATISTIC_MONTH_URL}><T>Statistic by month</T></Link>
            </Typography>
          </ListItem>
          <ListItem>
            <Typography color="textSecondary" variant="caption">
              <Link to={ROUTES.STATISTIC_FINANCE_URL}><T>Statistic by finance</T></Link>
            </Typography>
          </ListItem>
          <ListItem>
            <Typography color="textSecondary" variant="caption">
              <Link to={ROUTES.STATISTIC_YEAR_URL}><T>Statistic by year</T></Link>
            </Typography>
          </ListItem>
          <ListItem>
            <Typography color="textSecondary" variant="caption">
              <Link to={ROUTES.STATISTIC_STATUS_URL}><T>Statistic by status</T></Link>
            </Typography>
          </ListItem>
          <ListItem>
            <Typography color="textSecondary" variant="caption">
              <Link to={ROUTES.STATISTIC_CATEGORY_URL}><T>Statistic by category</T></Link>
            </Typography>
          </ListItem>
          <ListItem>
            <Typography color="textSecondary" variant="caption">
              <Link to={ROUTES.STATISTIC_API}>Статистика по API</Link>
            </Typography>
          </ListItem>
          <ListItem>
            <Typography color="textSecondary" variant="caption">
              <Link to={ROUTES.STATISTIC_PRODUCT_URL}>Статистика по Продуктам</Link>
            </Typography>
          </ListItem>
        </List>
      </Paper>
    </AppBar>
  )
}

StatisticDetail.propTypes = {
  app: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}

export default enhance(StatisticDetail)
