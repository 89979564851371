import React from 'react'
import { path } from 'ramda'
import PropTypes from 'prop-types'
import { compose, pure } from 'recompose'
import { connect } from 'react-redux'
import withStyles from '@material-ui/core/styles/withStyles'
import IconButton from '@material-ui/core/IconButton'
import t from '../../helpers/translate'
import LightIcon from '../Icon/LightIcon'
import DarkIcon from '../Icon/Dark'
import { THEME_STATE } from './reducer'
import { setThemeAction } from './actions'

const LIGHT = 'light'
const DARK = 'dark'

const styles = theme => ({
  root: {
    '& svg': {
      color: '#fff'
    }
  }
})

const mapStateToProps = state => ({
  theme: path([THEME_STATE, 'name'], state)
})

const mapDispatchToProps = {
  setThemeAction
}

const enhance = compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  pure
)

const ThemeSelect = ({ classes, theme, setThemeAction }) => {
  return (
    <div className={classes.root}>
      {theme === DARK && <IconButton
        size="small"
        onClick={() => setThemeAction('light')}
        aria-label={t('Light')}>
        <LightIcon />
      </IconButton >}
      {theme === LIGHT && <IconButton
        size="small"
        onClick={() => setThemeAction('dark')}
        aria-label={t('Dark')}>
        <DarkIcon />
      </IconButton >}
    </div>
  )
}

ThemeSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  setThemeAction: PropTypes.func,
  theme: PropTypes.string
}

export default enhance(ThemeSelect)
