import { compose, assocPath, path, prop, not, isNil, defaultTo } from 'ramda'
import React from 'react'
import classNames from 'classnames'
import { pure, setDisplayName, componentFromStream, createEventHandler } from 'recompose'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { fade } from '@material-ui/core/styles/colorManipulator'
import withStyles from '@material-ui/core/styles/withStyles'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import { addParamsRoute } from '../helpers/route'
import { parseParams } from '../helpers/urls'

const searchFormHistory = compose(
  prop('search'),
  parseParams,
  path(['location', 'search'])
)

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: theme.typography.fontFamily,
    position: 'relative',
    borderRadius: 2,
    background: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      background: fade(theme.palette.common.white, 0.25),
    }
  },

  search: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  input: {
    font: 'inherit',
    color: theme.palette.common.white,
    padding: `${theme.spacing.unit - 1}px ${theme.spacing.unit}px ${theme.spacing.unit - 1}px ${theme
      .spacing.unit * 9}px`,
    border: 0,
    display: 'block',
    verticalAlign: 'middle',
    whiteSpace: 'normal',
    background: 'none',
    width: '100%',
    margin: 0, // Reset for Safari
    '&:focus': {
      outline: 0,
    },
    '&::placeholder': {
      color: theme.palette.common.white
    },
  }
})

const enhance = compose(
  withRouter,
  withStyles(styles),
  pure,
  setDisplayName('SearchHeader')
)

const SearchHeader = componentFromStream(props$ => {
  const { stream: onChange$, handler: onChange } = createEventHandler()
  const { stream: onSubmit$, handler: onSubmit } = createEventHandler()

  props$
    .subscribe(({ history }) => {
      const search = searchFormHistory(history)
      const event = assocPath(['target', 'value'], search, {})
      onChange(event)
    })

  const search$ = onChange$
    .startWith(null)
    .map(path(['target', 'value']))

  search$
    .distinctUntilChanged()
    .debounceTime(500)
    .filter(compose(not, isNil))
    .withLatestFrom(props$)
    .subscribe(([search, { history }]) => {
      addParamsRoute({ search }, history)
    })

  onSubmit$
    .withLatestFrom(props$)
    .subscribe(([ search, { history } ]) => {
      addParamsRoute({ search }, history)
    })

  return props$.combineLatest(search$, ({ classes, ...props }, search) => (
    <div className={classNames(classes.root)}>
      <div className={classes.search}>
        <IconButton color="inherit" aria-label="Search" onClick={() => onSubmit(search)}>
          <SearchIcon />
        </IconButton>
      </div>
      <input
        value={defaultTo('', search)}
        onChange={onChange}
        placeholder={props.placeholder}
        className={classes.input}
        autoFocus={true}
      />
    </div>
  ))
})

SearchHeader.propTypes = {
  placeholder: PropTypes.string,
  classes: PropTypes.object.isRequired
}

export default enhance(SearchHeader)
