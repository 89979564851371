import AppLayout from '../../components/Layouts/AppLayout'
import { AsyncComponent } from '../../components/Layouts/RouterLayout'
import * as ROUTES from '../../constants/routes'

const getDashboardContainer = () =>
  import(/* webpackChunkName: "dashboard" */ './containers/DashboardContainer')
    .then(module => module.default)

export default () => ([
  {
    layout: AppLayout,
    exact: true,
    path: [ROUTES.DASHBOARD_PATH, ROUTES.ROOT_PATH],
    component: AsyncComponent(() => getDashboardContainer())
  }
])
