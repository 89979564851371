import { injectReducers } from '../../store/reducers'
import * as ROUTES from '../../constants/routes'
import AppLayout from '../../components/Layouts/AppLayout'
import { AsyncComponent } from '../../components/Layouts/RouterLayout'
import StatisticContainer from './containers/StatisticContainer'

const getApiContainer = store =>
  import(/* webpackChunkName: "monthstatistic" */ './reducers/api')
    .then(module => injectReducers(store, module.default))
    .then(() => import(/* webpackChunkName: "monthstatistic" */ './containers/ApiContainer'))
    .then(module => module.default)

const getMonthContainer = store =>
  import(/* webpackChunkName: "monthstatistic" */ './reducers/month')
    .then(module => injectReducers(store, module.default))
    .then(() => import(/* webpackChunkName: "monthstatistic" */ './containers/MonthContainer'))
    .then(module => module.default)

const getYearContainer = store =>
  import(/* webpackChunkName: "yearstatistic" */ './reducers/year')
    .then(module => injectReducers(store, module.default))
    .then(() => import(/* webpackChunkName: "yearstatistic" */ './containers/YearContainer'))
    .then(module => module.default)

const getProductContainer = store =>
  import(/* webpackChunkName: "productstatistic" */ './reducers/product')
    .then(module => injectReducers(store, module.default))
    .then(() => import(/* webpackChunkName: "yearstatistic" */ './containers/StatProductContainer'))
    .then(module => module.default)

const getFinanceContainer = store =>
  import(/* webpackChunkName: "financestatistic" */ './reducers/finance')
    .then(module => injectReducers(store, module.default))
    .then(() => import(/* webpackChunkName: "financestatistic" */ './containers/FinanceContainer'))
    .then(module => module.default)

const getStatusContainer = store =>
  import(/* webpackChunkName: "statusstatistic" */ './reducers/status')
    .then(module => injectReducers(store, module.default))
    .then(() => import(/* webpackChunkName: "statusstatistic" */ './containers/StatusContainer'))
    .then(module => module.default)

const getCategoryContainer = store =>
  import(/* webpackChunkName: "sizestatistic" */ './reducers/category')
    .then(module => injectReducers(store, module.default))
    .then(() => import(/* webpackChunkName: "sizestatistic" */ './containers/StatCategoryContainer'))
    .then(module => module.default)

export default (store) => ([
  {
    layout: AppLayout,
    exact: true,
    path: [ROUTES.STATISTIC_URL],
    component: StatisticContainer
  },
  {
    layout: AppLayout,
    path: [ROUTES.STATISTIC_MONTH_URL],
    component: AsyncComponent(() => getMonthContainer(store))
  },
  {
    layout: AppLayout,
    path: [ROUTES.STATISTIC_YEAR_URL],
    component: AsyncComponent(() => getYearContainer(store))
  },
  {
    layout: AppLayout,
    path: [ROUTES.STATISTIC_FINANCE_URL],
    component: AsyncComponent(() => getFinanceContainer(store))
  },
  {
    layout: AppLayout,
    path: [ROUTES.STATISTIC_STATUS_URL],
    component: AsyncComponent(() => getStatusContainer(store))
  },
  {
    layout: AppLayout,
    path: [ROUTES.STATISTIC_API],
    component: AsyncComponent(() => getApiContainer(store))
  },
  {
    layout: AppLayout,
    path: [ROUTES.STATISTIC_PRODUCT_URL],
    component: AsyncComponent(() => getProductContainer(store))
  },
  {
    layout: AppLayout,
    path: [ROUTES.STATISTIC_CATEGORY_URL],
    component: AsyncComponent(() => getCategoryContainer(store))
  }
])
