import React from 'react'
import { compose } from 'recompose'
import { create } from 'jss'
import { connect, Provider } from 'react-redux'
import { path } from 'ramda'
import MomentUtils from '@date-io/moment'
import ConnectedRouter from 'react-router-redux/ConnectedRouter'
import PropTypes from 'prop-types'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import JssProvider from 'react-jss/lib/JssProvider'

import { createGenerateClassName, jssPreset } from '@material-ui/core/styles'
import { muiTheme, muiThemeDark } from '../styles/themes'
import { THEME_STATE } from './ThemeSelect/reducer'

const generateClassName = createGenerateClassName()
const jss = create(jssPreset())
const mapStateToProps = state => ({
  theme: path([THEME_STATE, 'name'], state)
})
const enhance = compose(
  connect(mapStateToProps, null)
)

const App = ({ theme, ...props }) => {
  const activeTheme = theme === 'light' ? muiTheme : muiThemeDark

  return (
    <JssProvider jss={jss} generateClassName={generateClassName}>
      <Provider store={props.store}>
        <MuiThemeProvider theme={activeTheme}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale="en">
            <ConnectedRouter history={props.history}>
              {props.renderRoute(props.store)}
            </ConnectedRouter>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </Provider>
    </JssProvider>
  )
}

App.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  renderRoute: PropTypes.func.isRequired,
  theme: PropTypes.string
}

export default enhance(App)
