import { injectReducers } from '../../store/reducers'
import AppLayout from '../../components/Layouts/AppLayout'
import { AsyncComponent } from '../../components/Layouts/RouterLayout'
import * as ROUTES from '../../constants/routes'

const getPdfContractListContainer = store =>
  import(/* webpackChunkName: "PdfContract" */ './reducers')
    .then(module => injectReducers(store, module.default))
    .then(() => import(/* webpackChunkName: "PdfContract" */ './containers/PdfContractListContainer'))
    .then(module => module.default)

export default (store) => ([
  {
    layout: AppLayout,
    exact: true,
    path: [ROUTES.PDF_CONTRACT_LIST_PATH],
    component: AsyncComponent(() => getPdfContractListContainer(store))
  }
])
