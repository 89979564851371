/*
 * Action types
 */
export const MESSAGES = 'MESSAGES'
export const MESSAGES_OPEN = `${MESSAGES}_OPEN`
export const MESSAGES_CLOSE = `${MESSAGES}_CLOSE`

/*
 * Action creators
 */
export const openMessagesAction = () => {
  return {
    type: MESSAGES_OPEN,
  }
}

export const closeMessagesAction = () => {
  return {
    type: MESSAGES_CLOSE
  }
}
