import { injectReducers } from '../../store/reducers'
import AppLayout from '../../components/Layouts/AppLayout'
import { AsyncComponent } from '../../components/Layouts/RouterLayout'
import * as ROUTES from '../../constants/routes'

const getBusinessTypeContainer = store =>
  import(/* webpackChunkName: "BusinessType" */ './reducers')
    .then(module => injectReducers(store, module.default))
    .then(() => import(/* webpackChunkName: "BusinessType" */ './containers/BusinessTypeContainer'))
    .then(module => module.default)

export default (store) => ([
  {
    layout: AppLayout,
    exact: true,
    path: [
      ROUTES.BUSINESS_LIST_PATH,
    ],
    component: AsyncComponent(() => getBusinessTypeContainer(store))
  }
])
