import { injectReducers } from '../../store/reducers'
import AppLayout from '../../components/Layouts/AppLayout'
import { AsyncComponent } from '../../components/Layouts/RouterLayout'
import * as ROUTES from '../../constants/routes'

const getBranchContainer = store =>
  import(/* webpackChunkName: "Branch" */ './reducers')
    .then(module => injectReducers(store, module.default))
    .then(() => import(/* webpackChunkName: "Branch" */ './containers/BranchContainer'))
    .then(module => module.default)

export default (store) => ([
  {
    layout: AppLayout,
    exact: true,
    path: [
      ROUTES.BRANCH_LIST_PATH,
    ],
    component: AsyncComponent(() => getBranchContainer(store))
  }
])
