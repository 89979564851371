import createReducer from '../../helpers/createReducer'
import * as actions from './actions'

export const COMMENT_STATE = 'comment'
export const defaultState = {
  contentType: 'company',
  id: null,
  title: null,
  open: false,
  mount: false
}

const reducer = () => {
  return createReducer(defaultState, {
    [`${actions.COMMENT_MOUNT}`] (state, { payload }) {
      return {
        ...defaultState,
        ...payload,
        open: true,
        mount: true
      }
    },

    [`${actions.COMMENT_UNMOUNT}`] () {
      return defaultState
    },

    [`${actions.COMMENT_OPEN}`] (state) {
      return {
        ...state,
        open: true
      }
    },

    [`${actions.COMMENT_CLOSE}`] (state) {
      return {
        ...state,
        open: false
      }
    }
  })
}

export default reducer
