import createReducer from '../../../helpers/createReducer'
import * as actions from './actions'

export const MESSAGEBAR_STATE = 'messagebar'

export const defaultState = false

const reducer = () => {
  return createReducer(defaultState, {
    [`${actions.MESSAGES_OPEN}`] (state) {
      return true
    },

    [`${actions.MESSAGES_CLOSE}`] () {
      return false
    }
  })
}

export default reducer
