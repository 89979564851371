import { prop, path } from 'ramda'
import React from 'react'
import sprintf from 'sprintf'
import moment from 'moment'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose, withState, withHandlers, getContext } from 'recompose'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import SendIcon from '@material-ui/icons/Send'
import CloseIcon from '@material-ui/icons/Close'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import { reduxForm, Field } from 'redux-form'
import TextField from '../../components/Form/TextField'
import axios from '../../helpers/axios'
import t from '../../helpers/translate'
import { objectKeyToSnakeCase } from '../../helpers/mapper'
import { SUCCESS_TYPE, DANGER_TYPE, openSnackbarAction } from '../Snackbar/actions'
import DateField from '../Form/DateField'
import { openCommentAction, closeCommentAction, unmountCommentAction } from './actions'

export const API_COMPANY_HISTORY_URL = `/companies/%d/write-history/`

const styles = theme => ({
  root: {
    textAlign: 'center',
    margin: '0 auto',
    bottom: 0,
    position: 'fixed',
    width: 400,
    left: '50%',
    marginLeft: -200,
  },
  paper: {
    paddingRight: 10,
    paddingLeft: 10,
  }
})

export const form = 'CommentForm'
export const fields = ['company', 'comment']
export const formConfig = { form, fields }

const mapStateToProps = state => ({ state: prop('comment', state) })
const mapDispatchToProps = {
  onClose: closeCommentAction,
  onOpen: openCommentAction,
  onUnmount: unmountCommentAction,
  showSnackbar: openSnackbarAction,
}

const enhance = compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  getContext({ store: PropTypes.object.isRequired }),
  withState('loading', 'setLoading', false),
  withHandlers({
    onSubmit: props => (formValues) => {
      const { store, setLoading, showSnackbar, onUnmount } = props
      const id = path(['state', 'id'], props)
      const url = sprintf(API_COMPANY_HISTORY_URL, id)
      const data = objectKeyToSnakeCase({
        ...formValues,
        reminderDate: moment(prop('reminderDate', formValues)).format('YYYY-MM-DD')
      })

      setLoading(true)
      axios(store)
        .post(url, data)
        .then(() => {
          const action = {
            action: SUCCESS_TYPE,
            message: t('Successfully saved'),
            anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
          }
          showSnackbar(action)
          onUnmount()
          setLoading(false)
        })
        .catch(() => {
          const action = {
            action: DANGER_TYPE,
            message: t('Failed'),
            anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
          }
          showSnackbar(action)
          onUnmount()
          setLoading(false)
        })
    }
  }),
  reduxForm({ form })
)

const Comment = ({ classes, state, ...props }) => {
  return state.mount && (
    <div className={classes.root}>
      <form onSubmit={props.handleSubmit}>
        <Paper className={classes.paper}>
          <Grid container={true} alignItems="center" justify="space-between">
            <Grid item={true}>
              <Typography variant="title" gutterBottom={true}>
                {state.title}
              </Typography>
            </Grid>
            <Grid item={true}>
              <IconButton onClick={props.onUnmount}>
                <CloseIcon />
              </IconButton>

              {!state.open && (
                <IconButton onClick={props.onOpen}>
                  <KeyboardArrowUpIcon />
                </IconButton>
              )}
              {state.open && (
                <IconButton onClick={props.onClose}>
                  <KeyboardArrowDownIcon />
                </IconButton>
              )}

              <IconButton disabled={props.loading} type="submit">
                <SendIcon />
              </IconButton>
            </Grid>
          </Grid>
          {state.open && (
            <Grid container={true} direction="column">
              <Grid item={true}>
                <Field
                  name="text"
                  fullWidth={true}
                  label={t('Record')}
                  component={TextField}
                />
              </Grid>

              <Grid item={true}>
                <Field
                  component={DateField}
                  name="reminderDate"
                  label={t('Remember date')}
                  fullWidth={true}
                />
              </Grid>
            </Grid>
          )}
        </Paper>
      </form>
    </div>
  )
}

Comment.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.shape({
    mount: PropTypes.bool.isRequired,
    title: PropTypes.string,
    open: PropTypes.bool.isRequired
  }).isRequired,
  onOpen: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUnmount: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
}

export default enhance(Comment)
