// ROOT
export const ROOT = ''
export const ROOT_PATH = `/${ROOT}`

// SIGN IN ROUTES
export const SIGN_IN = 'sign-in'
export const SIGN_IN_URL = `/${SIGN_IN}`

// RECOVERY ROUTES
export const RECOVERY = 'recovery'
export const RECOVERY_URL = `/${RECOVERY}`

// RECOVERY THANK YOU ROUTES
export const RECOVERY_THANK_YOU = 'thank-you'
export const RECOVERY_THANK_YOU_URL = `/${RECOVERY}/${RECOVERY_THANK_YOU}`

// RESET PASSWORD
export const RESET_PASSWORD = 'reset-password'
export const RESET_PASSWORD_URL = `/${RESET_PASSWORD}/:code`

// SIGN UP ROUTES
export const SIGN_UP = 'sign-up'
export const SIGN_UP_URL = `/${SIGN_UP}`
export const SIGN_UP_VERIFY_URL = `/${SIGN_UP}-verify/:code`

// SIGN UP THANK YOU ROUTE
export const SIGN_UP_THANK_YOU = 'thank-you'
export const SIGN_UP_THANK_YOU_URL = `/${SIGN_UP}/${SIGN_UP_THANK_YOU}`

// SIGN UP THANK YOU ROUTE
export const SIGN_UP_RESEND_MESSAGE = 'resend'
export const SIGN_UP_RESEND_MESSAGE_URL = `/${SIGN_UP}/${SIGN_UP_RESEND_MESSAGE}`

// SIGN UP THANK YOU ROUTE
export const SIGN_UP_EMAIL_CONFIRM = 'confirm'
export const SIGN_UP_EMAIL_CONFIRM_URL = `/${SIGN_UP}/${SIGN_UP_EMAIL_CONFIRM}/:code`

// INTERNAL SERVER ERROR PAGE
export const INTERNAL_SERVER_ERROR = '500'
export const INTERNAL_SERVER_ERROR_URL = `/${INTERNAL_SERVER_ERROR}`

// COMPANY_MY ROUTES
export const COMPANY_MY = 'c'
export const COMPANY_MY_LIST_URL = `/${COMPANY_MY}`
export const COMPANY_MY_URL = `${COMPANY_MY_LIST_URL}/:companyId`
export const COMPANY_MY_PATH = `${COMPANY_MY_LIST_URL}/%d`

// DASHBOARD
export const DASHBOARD = 'dashboard'
export const DASHBOARD_URL = `/${DASHBOARD}`
export const DASHBOARD_PATH = `/${DASHBOARD}`

// USER ROUTE
export const USER = 'users'
export const USER_LIST_PATH = `/${USER}`

// USER ROUTE
export const GROUPS = 'groups'
export const GROUPS_LIST_PATH = `/${GROUPS}`

// COMPANY ROUTES
export const COMPANY = 'company'
export const COMPANY_LIST_PATH = `/${COMPANY}`
export const COMPANY_DETAIL_PATH = `${COMPANY_LIST_PATH}/:id`
export const COMPANY_DETAIL_TAB_PATH = `${COMPANY_LIST_PATH}/:id/:tab`
export const COMPANY_DETAIL_URL = `${COMPANY_LIST_PATH}/%d`
export const COMPANY_DETAIL_TAB_URL = `${COMPANY_LIST_PATH}/%d/%s`

// CONTRACT ROUTES
export const CONTRACT = 'contract'
export const CONTRACT_LIST_PATH = `/${CONTRACT}`
export const CONTRACT_DETAIL_PATH = `${CONTRACT_LIST_PATH}/:id`
export const CONTRACT_DETAIL_TAB_PATH = `${CONTRACT_LIST_PATH}/:id/:tab`
export const CONTRACT_DETAIL_URL = `${CONTRACT_LIST_PATH}/%d`
export const CONTRACT_DETAIL_TAB_URL = `${CONTRACT_LIST_PATH}/%d/%s`

// PRODUCT ROUTES
export const PRODUCT = 'product'
export const PRODUCT_LIST_PATH = `/${PRODUCT}`
export const PRODUCT_DETAIL_PATH = `${PRODUCT_LIST_PATH}/:id`
export const PRODUCT_DETAIL_URL = `${PRODUCT_LIST_PATH}/%d`

// NEWS ROUTES
export const NEWS = 'news'
export const NEWS_LIST_PATH = `/${NEWS}`
export const NEWS_DETAIL_PATH = `${NEWS_LIST_PATH}/:id`
export const NEWS_DETAIL_URL = `${NEWS_LIST_PATH}/%d`

// COMMON ROUTE
export const COMMON = 'common'
export const COMMON_SETTINGS = `${COMMON}/settings`
export const COMMON_SETTINGS_URL = `/${COMMON_SETTINGS}`
export const COMMON_SETTINGS_PATH = `/${COMMON_SETTINGS}`

// MESSAGE ROUTES
export const MESSAGE = 'message'
export const MESSAGE_LIST_PATH = `/${MESSAGE}`
export const MESSAGE_DETAIL_PATH = `${MESSAGE_LIST_PATH}/:id`
export const MESSAGE_DETAIL_URL = `${MESSAGE_LIST_PATH}/%d`

// MESSAGE ROUTES
export const FEE = 'fee'
export const FEE_LIST_PATH = `/${FEE}`

// CATEGORY ROUTES
export const BUSINESS = 'business-type'
export const BUSINESS_LIST_PATH = `/${BUSINESS}`

// SETTINGS ROUTE
export const SETTINGS = 'settings'
export const SETTINGS_PATH = `/${SETTINGS}`
export const REGION_LIST_PATH = `/${SETTINGS}/region`
export const CITY_LIST_PATH = `/${SETTINGS}/city`
export const COUNTRY_LIST_PATH = `/${SETTINGS}/country`
export const DISTRICT_LIST_PATH = `/${SETTINGS}/district`
export const BRANCH_LIST_PATH = `/${SETTINGS}/branch`
export const ACTIVITY_LIST_PATH = `/${SETTINGS}/business-activities`
export const ECONOMIC_ACTIVITY_LIST_PATH = `/${SETTINGS}/economic-activities`
export const OWNERSHIP_LIST_PATH = `/${SETTINGS}/ownership`
export const PDF_CONTRACT_LIST_PATH = `/${SETTINGS}/contracts`
export const PDF_AGREEMENT_LIST_PATH = `/${SETTINGS}/agreement`
export const PRODUCT_TYPE_LIST_PATH = `/${SETTINGS}/product-type`
export const BANK_LIST_PATH = `/${SETTINGS}/bank`

// STATISTIC ROUTE
export const STATISTIC = 'statistic'
export const STATISTIC_URL = `/${STATISTIC}/`
export const STATISTIC_PATH = `/${STATISTIC}`
export const STATISTIC_MONTH_URL = `/${STATISTIC}/month`
export const STATISTIC_YEAR_URL = `/${STATISTIC}/year`
export const STATISTIC_PRODUCT_URL = `/${STATISTIC}/product`
export const STATISTIC_FINANCE_URL = `/${STATISTIC}/finance`
export const STATISTIC_STATUS_URL = `/${STATISTIC}/status`
export const STATISTIC_CATEGORY_URL = `/${STATISTIC}/category`
export const STATISTIC_API = `/${STATISTIC}/api`
