// API STATE
export const SING_IN = 'signIn'
export const SIGN_UP = 'signUp'
export const SIGN_OUT = 'singOut'
export const SIGN_UP_EMAIL_CONFIRM = 'signUpEmailConfirm'
export const RESEND_MESSAGE = 'resendMessage'
export const TWITTER_REDIRECT = 'twitterRedirect'
export const RECOVERY = 'recovery'
export const RESET_PASSWORD = 'resetPassword'
export const USER_COMPANIES = 'userCompanies'
export const ME = 'me'
export const PERMISSIONS = 'permissions'

// User crud state
export const USER_LIST = 'userList'
export const USER_ADD = 'userAdd'
export const USER_DETAIL = 'userDetail'
export const USER_EDIT = 'userEdit'
export const USER_DELETE = 'userDelete'
export const USER_HISTORY = 'userHistory'
export const CHANGE_PASSWORD = 'changePassword'

// Groups crud state
export const GROUPS_LIST = 'groupsList'
export const GROUPS_ADD = 'groupsAdd'
export const GROUPS_DETAIL = 'groupsDetail'
export const GROUPS_EDIT = 'groupsEdit'
export const GROUPS_DELETE = 'groupsDelete'

// Company crud state
export const COMPANY_LIST = 'companyList'
export const COMPANY_DETAIL = 'companyDetail'
export const COMPANY_DETAIL_DOCUMENT = 'companyDetailDocument'
export const COMPANY_ADD = 'companyAdd'
export const COMPANY_EDIT = 'companyEdit'
export const COMPANY_DELETE = 'companyDelete'
export const COMPANY_HISTORY = 'companyHistory'
export const COMPANY_MERGE = 'companyMerge'
export const HISTORY_WRITE = 'historyWrite'
export const HISTORY_DELETE = 'historyDelete'

// Contract crud state
export const CONTRACT_LIST = 'contractList'
export const CONTRACT_DETAIL = 'contractDetail'
export const CONTRACT_ADD = 'contractAdd'
export const CONTRACT_EDIT = 'contractEdit'
export const CONTRACT_DELETE = 'contractDelete'

// Product crud state
export const PRODUCT_LIST = 'productList'
export const PRODUCT_DETAIL = 'productDetail'
export const PRODUCT_ADD = 'productAdd'
export const PRODUCT_EDIT = 'productEdit'
export const PRODUCT_DELETE = 'productDelete'

// News crud state
export const NEWS_LIST = 'newsList'
export const NEWS_DETAIL = 'newsDetail'
export const NEWS_ADD = 'newsAdd'
export const NEWS_EDIT = 'newsEdit'
export const NEWS_DELETE = 'newsDelete'

// Statistic crud state
export const STATISTIC_BY_MONTH = 'statisticByMonth'
export const STATISTIC_COMPANIES = 'statisticCompanies'
export const STATISTIC_BY_YEAR = 'statisticByYear'
export const STATISTIC_BY_PRODUCT = 'statisticByProduct'
export const STATISTIC_BY_API = 'statisticByApi'
export const STATISTIC_BY_STATUS = 'statisticByStatus'
export const STATISTIC_BY_CATEGORY = 'statisticByCategory'
export const FINANCE_STATISTIC_BY_YEAR = 'financeStatisticByYear'
export const FINANCE_INVOICE_LIST = 'financeInvoiceList'
export const FINANCE_STATISTIC_BY_DEBTOR = 'financeStatisticByDebtor'

// Message crud state
export const MESSAGE_LIST = 'messageList'
export const MESSAGE_DETAIL = 'messageDetail'
export const MESSAGE_DELETE = 'messageDelete'
export const MESSAGE_ADD = 'messageAdd'
export const MESSAGE_EDIT = 'messageEdit'

// PDF contract crud state
export const PDF_CONTRACT_LIST = 'pdfContractList'
export const PDF_CONTRACT_DETAIL = 'pdfContractDetail'
export const PDF_CONTRACT_ADD = 'pdfContractAdd'
export const PDF_CONTRACT_EDIT = 'pdfContractEdit'

// PDF agreement crud state
export const PDF_AGREEMENT_LIST = 'pdfAgreementList'
export const PDF_AGREEMENT_DETAIL = 'pdfAgreementDetail'
export const PDF_AGREEMENT_ADD = 'pdfAgreementAdd'
export const PDF_AGREEMENT_EDIT = 'pdfAgreementEdit'

// Region crud state
export const REGION_LIST = 'regionList'
export const REGION_DETAIL = 'regionDetail'
export const REGION_DELETE = 'regionDelete'
export const REGION_ADD = 'regionAdd'
export const REGION_EDIT = 'regionEdit'

// Region crud state
export const COUNTRY_LIST = 'countryList'
export const COUNTRY_DETAIL = 'countryDetail'
export const COUNTRY_DELETE = 'countryDelete'
export const COUNTRY_ADD = 'countryAdd'
export const COUNTRY_EDIT = 'countryEdit'

// District crud state
export const DISTRICT_LIST = 'districtList'
export const DISTRICT_DETAIL = 'districtDetail'
export const DISTRICT_DELETE = 'districtDelete'
export const DISTRICT_ADD = 'districtAdd'
export const DISTRICT_EDIT = 'districtEdit'

// Ownership crud state
export const OWNERSHIP_LIST = 'ownershipList'
export const OWNERSHIP_DETAIL = 'ownershipDetail'
export const OWNERSHIP_DELETE = 'ownershipDelete'
export const OWNERSHIP_ADD = 'ownershipAdd'
export const OWNERSHIP_EDIT = 'ownershipEdit'

// Region crud state
export const ACTIVITY_LIST = 'activityList'
export const ACTIVITY_DETAIL = 'activityDetail'
export const ACTIVITY_DELETE = 'activityDelete'
export const ACTIVITY_ADD = 'activityAdd'
export const ACTIVITY_EDIT = 'activityEdit'

// Economic activity crud state
export const ECONOMIC_ACTIVITY_LIST = 'economicActivityList'
export const ECONOMIC_ACTIVITY_DETAIL = 'economicActivityDetail'
export const ECONOMIC_ACTIVITY_DELETE = 'economicActivityDelete'
export const ECONOMIC_ACTIVITY_ADD = 'economicActivityAdd'
export const ECONOMIC_ACTIVITY_EDIT = 'economicActivityEdit'

// Product type crud state
export const PRODUCT_TYPE_LIST = 'productTypeList'
export const PRODUCT_TYPE_DETAIL = 'productTypeDetail'
export const PRODUCT_TYPE_DELETE = 'productTypeDelete'
export const PRODUCT_TYPE_ADD = 'productTypeAdd'
export const PRODUCT_TYPE_EDIT = 'productTypeEdit'

// Business type crud state
export const BUSINESS_TYPE_LIST = 'businessTypeList'
export const BUSINESS_TYPE_DETAIL = 'businessTypeDetail'
export const BUSINESS_TYPE_DELETE = 'businessTypeDelete'
export const BUSINESS_TYPE_ADD = 'businessTypeAdd'
export const BUSINESS_TYPE_EDIT = 'businessTypeEdit'

// Financial crud state
export const FINANCIAL_LIST = 'financialList'
export const FINANCIAL_ADD = 'financialAdd'
export const FINANCIAL_EDIT = 'financialEdit'
export const FINANCIAL_DETAIL = 'financialDetail'

// Invoices crud state
export const INVOICES_LIST = 'invoicesList'
export const CONTRACT_INVOICES_LIST = 'contractInvoicesList'
export const COMMENT_CONTRACT = 'commentContract'
export const CONTRACT_INVOICES_CREATE = 'contractInvoicesCreate'
export const INVOICES_CREATE = 'invoicesCreate'
export const INVOICES_DETAIL = 'invoicesDetail'
export const CONTRACT_FINANCIAL_HISTORY_LIST = 'contractFinancialHistoryList'

// Messages crud state
export const MESSAGES_LIST = 'messagesList'
export const MESSAGES_COUNT = 'messagesCount'

// Pay amount crud state
export const PAY_AMOUNT = 'payAmount'
export const UN_PAY_AMOUNT = 'unPayAmount'
export const INVOICE_ACTIVATE = 'invoiceActivate'
export const CONTRACT_ACTIVATE = 'contractActivate'

// Fee crud state
export const FEE_LIST = 'feeList'
export const FEE_ADD = 'feeAdd'
export const FEE_DETAIL = 'feeDetail'
export const FEE_EDIT = 'feeEdit'
export const FEE_DELETE = 'feeDelete'

// Bank crud state
export const BANK_LIST = 'bankList'
export const BANK_ADD = 'bankAdd'
export const BANK_DETAIL = 'bankDetail'
export const BANK_EDIT = 'bankEdit'
export const BANK_DELETE = 'bankDelete'

// Bank crud state
export const CITY_LIST = 'cityList'
export const CITY_ADD = 'cityAdd'
export const CITY_DETAIL = 'cityDetail'
export const CITY_EDIT = 'cityEdit'
export const CITY_DELETE = 'cityDelete'

// Bank crud state
export const BRANCH_LIST = 'branchList'
export const BRANCH_ADD = 'branchAdd'
export const BRANCH_DETAIL = 'branchDetail'
export const BRANCH_EDIT = 'branchEdit'
export const BRANCH_DELETE = 'branchDelete'
