import { injectReducers } from '../../store/reducers'
import AppLayout from '../../components/Layouts/AppLayout'
import { AsyncComponent } from '../../components/Layouts/RouterLayout'
import * as ROUTES from '../../constants/routes'

const getContractContainer = store =>
  import(/* webpackChunkName: "contract" */ './reducers')
    .then(module => injectReducers(store, module.default))
    .then(() => import(/* webpackChunkName: "contract" */ './containers/ContractContainer'))
    .then(module => module.default)

export default (store) => ([
  {
    layout: AppLayout,
    exact: true,
    path: [
      ROUTES.CONTRACT_LIST_PATH,
      ROUTES.CONTRACT_DETAIL_PATH,
      ROUTES.CONTRACT_DETAIL_TAB_PATH
    ],
    component: AsyncComponent(() => getContractContainer(store))
  }
])
