import React from 'react'
import PropTypes from 'prop-types'
import { compose, getContext, pure } from 'recompose'
import { all, path, find, propEq, isEmpty, isNil } from 'ramda'

const enhance = compose(
  getContext({ store: PropTypes.object.isRequired }),
  pure
)

export const hasAccess = (accesses, permissions) =>
  all((item) => {
    if (permissions) {
      return find(propEq('codename', item))(permissions)
    }
    return false
  }, accesses)

const PermissionWrapper = ({ accesses = [], children, ...props }) => {
  const store = props.store.getState()
  const permissions = path(['permissions', 'data'], store)
  const isSuperuser = path(['me', 'data', 'isSuperuser'], store)
  const access = hasAccess(accesses, permissions) || isEmpty(accesses) || isNil(accesses)
  return (
    <React.Fragment>
      {(access || isSuperuser) && React.cloneElement(children, props)}
    </React.Fragment>
  )
}

PermissionWrapper.propTypes = {
  children: PropTypes.node,
  store: PropTypes.object.isRequired,
  accesses: PropTypes.array
}

export default enhance(PermissionWrapper)
