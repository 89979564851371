import React from 'react'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import PublicIcon from '@material-ui/icons/Public'
import PieChartIcon from '@material-ui/icons/PieChart'
import RedeemIcon from '@material-ui/icons/Redeem'
import SettingsIcon from '@material-ui/icons/Settings'
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter'
import t from '../helpers/translate'
import * as ROUTES from './routes'

export default [
  {
    name: ROUTES.COMPANY,
    to: ROUTES.COMPANY_LIST_PATH,
    title: t('Company'),
    icon: (<AccessTimeIcon />),
    permissions: ['list_company']
  },
  {
    name: ROUTES.CONTRACT,
    to: ROUTES.CONTRACT_LIST_PATH,
    title: t('Contracts'),
    icon: (<FormatAlignCenterIcon />),
    permissions: ['list_contract']
  },
  {
    name: ROUTES.PRODUCT,
    to: ROUTES.PRODUCT_LIST_PATH,
    title: t('Products'),
    icon: (<RedeemIcon />),
    permissions: ['list_product']
  },
  {
    name: ROUTES.STATISTIC,
    to: ROUTES.STATISTIC_PATH,
    title: t('Statistic'),
    icon: (<PieChartIcon />),
    permissions: ['statistics_company']
  },
  {
    name: ROUTES.NEWS,
    to: ROUTES.NEWS_LIST_PATH,
    title: t('News'),
    icon: (<PublicIcon />),
    permissions: ['list_news'],
    divider: true
  },
  {
    name: ROUTES.SETTINGS,
    to: ROUTES.SETTINGS_PATH,
    title: t('Settings'),
    icon: (<SettingsIcon />),
    permissions: ['list_user'],
  }
]
