import React from 'react'
import PropTypes from 'prop-types'
import { compose, mapPropsStream, pure } from 'recompose'
import { connect } from 'react-redux'
import withStyles from '@material-ui/core/styles/withStyles'
import { isNil, path } from 'ramda'
import Badge from '@material-ui/core/es/Badge/Badge'
import AlarmIcon from '@material-ui/icons/AccessAlarm'
import IconButton from '@material-ui/core/es/IconButton/IconButton'
import Rx from 'rxjs'
import { getDataFromState } from '../../helpers/get'
import { MESSAGES_COUNT } from '../../constants/states'
import { getMessagesCountAction } from '../Messages/actions/messages'
import { openMessagesAction } from './reducers/actions'

const styles = {
  badge: {
    color: '#fff'
  },
}

const CALL_INTERVAL_TIME = 40000 // 40 second

const mapStateToProps = (state) => ({
  count: path(['data', 'count'], getDataFromState(MESSAGES_COUNT, state))
})

const enhance = compose(
  withStyles(styles),
  connect(mapStateToProps, {
    open: openMessagesAction,
    getMessagesCountAction
  }),
  mapPropsStream(props$ => {
    const interval$ = Rx.Observable
      .timer(0, CALL_INTERVAL_TIME)
      .withLatestFrom(props$)
      .do(([interval, { getMessagesCountAction, ...props }]) => {
        if (interval !== 0 || isNil(props.count)) {
          getMessagesCountAction()
        }
      })

    return props$.combineLatest(interval$, props => props)
  }),
  pure
)

const BadgeMessages = ({ classes, count, open }) => {
  return (
    <IconButton className={classes.badge} onClick={() => open()}>
      <Badge badgeContent={count} color="secondary">
        <AlarmIcon />
      </Badge>
    </IconButton>
  )
}

BadgeMessages.defaultProps = {
  count: 0
}

BadgeMessages.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.func.isRequired,
  count: PropTypes.number,
}

export default enhance(BadgeMessages)
