import { injectReducers } from '../../store/reducers'
import AppLayout from '../../components/Layouts/AppLayout'
import { AsyncComponent } from '../../components/Layouts/RouterLayout'
import * as ROUTES from '../../constants/routes'

const getFeeContainer = store =>
  import(/* webpackChunkName: "Fee" */ './reducers')
    .then(module => injectReducers(store, module.default))
    .then(() => import(/* webpackChunkName: "Fee" */ './containers/FeeContainer'))
    .then(module => module.default)

export default (store) => ([
  {
    layout: AppLayout,
    exact: true,
    path: [
      ROUTES.FEE_LIST_PATH
    ],
    component: AsyncComponent(() => getFeeContainer(store))
  }
])
