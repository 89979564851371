import createReducer from '../../helpers/createReducer'
import * as actions from './actions'

export const THEME_STATE = 'theme'
export const defaultState = {
  name: localStorage.getItem(THEME_STATE) || 'light'
}

const reducer = () => {
  return createReducer(defaultState, {
    [`${actions.SET_THEME}`] (state, { payload }) {
      localStorage.setItem(THEME_STATE, payload)
      return {
        ...defaultState,
        name: payload
      }
    }
  })
}

export default reducer
