import { addIndex, map, prop } from 'ramda'
import classNames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import menus from '../constants/menus'
import PermissionWrapper from './PermissionWrapper'

const styles = theme => ({
  root: {
    position: 'fixed',
    paddingTop: 24
  },
  item: {
    borderRadius: theme.spacing.unit / 4
  },
  active: {
    color: theme.components.menu.active,
    '& svg': {
      color: theme.components.menu.active
    }
  }
})

const Menu = ({ classes, ...props }) => {
  return (
    <MenuList className={classes.root} component="nav">
      {addIndex(map)((item, index) => (
        <div key={index}>
          <PermissionWrapper accesses={prop('permissions', item)}>
            <React.Fragment>
              <MenuItem
                className={classNames(
                  classes.item,
                  { [classes.active]: item.name === props.active }
                )}
                component={Link}
                to={item.to}>
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                {item.title}
              </MenuItem>
              { item.divider && <hr /> }
            </React.Fragment>
          </PermissionWrapper>
        </div>
      ), menus)}
    </MenuList>
  )
}

Menu.propTypes = {
  classes: PropTypes.object,
  active: PropTypes.string.isRequired,
  divider: PropTypes.bool
}

export default withStyles(styles)(Menu)
