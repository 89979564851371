import React from 'react'
import PropTypes from 'prop-types'
import Divider from '@material-ui/core/es/Divider/Divider'
import Typography from '@material-ui/core/es/Typography/Typography'
import Paper from '@material-ui/core/es/Paper/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import { compose, pure } from 'recompose'
import { Link } from 'react-router-dom'
import PeopleIcon from '@material-ui/icons/People'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import MapIcon from '@material-ui/icons/Map'
import StreetviewIcon from '@material-ui/icons/Streetview'
import WorkIcon from '@material-ui/icons/Work'
import Contract from '@material-ui/icons/Assignment'
import ForumIcon from '@material-ui/icons/Forum'
import BankIcon from '@material-ui/icons/AccountBalance'
import BusinessIcon from '@material-ui/icons/Business'
import MultilineChartIcon from '@material-ui/icons/MultilineChart'
import List from '@material-ui/core/es/List/List'
import ListItem from '@material-ui/core/es/ListItem/ListItem'
import ListItemText from '@material-ui/core/es/ListItemText/ListItemText'
import ListItemIcon from '@material-ui/core/es/ListItemIcon/ListItemIcon'
import * as ROUTES from '../../../constants/routes'
import AppBar from '../../../components/AppBar'
import T from '../../../components/T'

const styles = theme => ({
  root: {
    marginBottom: 20,
    background: theme.table.backgroundColor,
    '& *': {
      color: theme.table.textColor,
    },
    '&:last-child': {
      marginBottom: 0
    }
  }
})

const enhance = compose(
  withStyles(styles),
  pure
)

const Settings = props => {
  const { classes } = props
  return (
    <AppBar active={ROUTES.SETTINGS} {...props.app}>
      <Paper className={classes.root} elevation={1}>
        <List className={classes.root}>
          <ListItem>
            <ListItemText><T>Users</T></ListItemText>
          </ListItem>
          <Divider component="li" />
          <ListItem>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <Typography color="textSecondary" variant="caption">
              <Link to={ROUTES.GROUPS_LIST_PATH}><T>Groups</T></Link>
            </Typography>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <PeopleOutlineIcon />
            </ListItemIcon>
            <Typography color="textSecondary" variant="caption">
              <Link to={ROUTES.USER_LIST_PATH}><T>Users</T></Link>
            </Typography>
          </ListItem>
        </List>
      </Paper>

      <Paper className={classes.root} elevation={1}>
        <List className={classes.root}>
          <ListItem>
            <ListItemText><T>Notification</T></ListItemText>
          </ListItem>
          <Divider component="li" />
          <ListItem>
            <ListItemIcon>
              <ForumIcon />
            </ListItemIcon>
            <Typography color="textSecondary" variant="caption">
              <Link to={ROUTES.MESSAGE_LIST_PATH}><T>SMS settings</T></Link>
            </Typography>
          </ListItem>
        </List>
      </Paper>

      <Paper className={classes.root} elevation={1}>
        <List className={classes.root}>
          <ListItem>
            <ListItemText><T>Billing</T></ListItemText>
          </ListItem>
          <Divider component="li" />
          <ListItem>
            <ListItemIcon>
              <AttachMoneyIcon />
            </ListItemIcon>
            <Typography color="textSecondary" variant="caption">
              <Link to={ROUTES.FEE_LIST_PATH}><T>Fee</T></Link>
            </Typography>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <AttachMoneyIcon />
            </ListItemIcon>
            <Typography color="textSecondary" variant="caption">
              <Link to={ROUTES.BUSINESS_LIST_PATH}>Категория</Link>
            </Typography>
          </ListItem>
        </List>
      </Paper>

      <Paper className={classes.root} elevation={1}>
        <List className={classes.root}>
          <ListItem>
            <ListItemText><T>ГЕО данные</T></ListItemText>
          </ListItem>
          <Divider component="li" />
          <ListItem>
            <ListItemIcon>
              <MapIcon />
            </ListItemIcon>
            <Typography color="textSecondary" variant="caption">
              <Link to={ROUTES.COUNTRY_LIST_PATH}><T>Страны</T></Link>
            </Typography>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <MapIcon />
            </ListItemIcon>
            <Typography color="textSecondary" variant="caption">
              <Link to={ROUTES.REGION_LIST_PATH}><T>Regions</T></Link>
            </Typography>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <MapIcon />
            </ListItemIcon>
            <Typography color="textSecondary" variant="caption">
              <Link to={ROUTES.CITY_LIST_PATH}><T>Города</T></Link>
            </Typography>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <StreetviewIcon />
            </ListItemIcon>
            <Typography color="textSecondary" variant="caption">
              <Link to={ROUTES.DISTRICT_LIST_PATH}><T>Districts</T></Link>
            </Typography>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <StreetviewIcon />
            </ListItemIcon>
            <Typography color="textSecondary" variant="caption">
              <Link to={ROUTES.BRANCH_LIST_PATH}><T>Филиалы</T></Link>
            </Typography>
          </ListItem>
        </List>
      </Paper>

      <Paper className={classes.root} elevation={1}>
        <List className={classes.root}>
          <ListItem>
            <ListItemText><T>System</T></ListItemText>
          </ListItem>
          <Divider component="li" />
          <ListItem>
            <ListItemIcon>
              <Contract />
            </ListItemIcon>
            <Typography color="textSecondary" variant="caption">
              <Link to={ROUTES.PDF_AGREEMENT_LIST_PATH}><T>Agreement</T></Link>
            </Typography>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Contract />
            </ListItemIcon>
            <Typography color="textSecondary" variant="caption">
              <Link to={ROUTES.PDF_CONTRACT_LIST_PATH}><T>Contracts</T></Link>
            </Typography>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <WorkIcon />
            </ListItemIcon>
            <Typography color="textSecondary" variant="caption">
              <Link to={ROUTES.OWNERSHIP_LIST_PATH}><T>Ownership</T></Link>
            </Typography>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <BusinessIcon />
            </ListItemIcon>
            <Typography color="textSecondary" variant="caption">
              <Link to={ROUTES.ACTIVITY_LIST_PATH}><T>Type of activity according to KVED</T></Link>
            </Typography>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <MultilineChartIcon />
            </ListItemIcon>
            <Typography color="textSecondary" variant="caption">
              <Link to={ROUTES.ECONOMIC_ACTIVITY_LIST_PATH}><T>Type of activity according to OKED</T></Link>
            </Typography>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <MultilineChartIcon />
            </ListItemIcon>
            <Typography color="textSecondary" variant="caption">
              <Link to={ROUTES.PRODUCT_TYPE_LIST_PATH}><T>Product class type</T></Link>
            </Typography>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <BankIcon />
            </ListItemIcon>
            <Typography color="textSecondary" variant="caption">
              <Link to={ROUTES.BANK_LIST_PATH}><T>Banks</T></Link>
            </Typography>
          </ListItem>
        </List>
      </Paper>
    </AppBar>
  )
}

Settings.propTypes = {
  app: PropTypes.object.isRequired,
  classes: PropTypes.object,
}

export default enhance(Settings)
