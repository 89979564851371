import { curry, compose, defaultTo, path, assoc, __ } from 'ramda'
import { appendParamsToUrl } from './urls'

export const redirect = curry(({ pathname, params }, history) =>
  compose(
    history.push,
    appendParamsToUrl(params),
    defaultTo(path(['location', 'pathname'], history))
  )(pathname)
)

export const addParamsRoute = curry((params, history) =>
  compose(
    redirect(__, history),
    assoc('pathname', __, {}),
    appendParamsToUrl(params),
    path(['location', 'search']),
  )(history)
)

export const replace = curry(({ pathname, params }, history) =>
  compose(
    history.replace,
    appendParamsToUrl(params),
    defaultTo(path(['location', 'pathname'], history))
  )(pathname)
)

export const replaceParamsRoute = curry((params, history) =>
  compose(
    replace(__, history),
    assoc('pathname', __, {}),
    appendParamsToUrl(params),
    path(['location', 'search']),
  )(history)
)

export const removeParam = (paramName, history) => {
  let search = ''
  window.location.search.substr(1).split('&').forEach((i, k) => {
    const [name, paramValue] = i.split('=')

    if (name !== paramName) {
      search += `${k === 0 ? '?' : '&'}${name}=${paramValue}`
    }
  })

  if (history) {
    history.push({
      search: search.substr(1)
    })
  }

  return search
}
